import { AxiosInstance, AxiosResponse } from 'axios';
import { BidGroupConfigModel } from 'models/Bid';
import LotModel from 'models/Lot';
import { decodeHtmlEntity, sortBidsByDate } from 'utils/common';

export type IBidsApi = {
    get: (status: string[], from: string, to: string) => Promise<AxiosResponse<LotModel[]>>,
    getGroups: (userId: string) => Promise<AxiosResponse>,
    deleteGroup: (userId: string, configId: string) => Promise<AxiosResponse>,
    create: (userId: string, bidAmount: number, bidGroup: BidGroupConfigModel, curWinLimit: string, lot: LotModel) => Promise<AxiosResponse<any>>,
    delete: (bidId: string) => Promise<AxiosResponse>,
    setType: (bidId: string, type: string) => Promise<AxiosResponse<any>>,
    setStatus: (bidId: string, status: string) => Promise<AxiosResponse<any>>,
}

export default class BidsApi implements IBidsApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    };

    get = (status: string[], from: string, to: string) => {
        return this.axios.get<LotModel[]>(`/auctions/lots`, {
            params: {
                status,
                from,
                to,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].auctionStatus === '') {
                        res.data[i].auctionStatus = 'active'; // TODO wait for update
                    }
                    res.data[i].clients.sort(sortBidsByDate);
                    res.data[i].modelGrade = decodeHtmlEntity(res.data[i].modelGrade);
                    res.data[i].transmissionType = decodeHtmlEntity(res.data[i].transmissionType);
                }

                return res;
            });
    };

    getGroups = (userId: string) => {
        return this.axios.get(`/bids/users/${userId}/configs`);
    };

    deleteGroup = (userId: string, configId: string) => {
        return this.axios.delete(`/bids/users/${userId}/configs/${configId}`);
    };

    create = (userId: string, bidAmount: number, bidGroup: BidGroupConfigModel, curWinLimit: string, lot: LotModel) => {
        return this.axios.post('/auctions/bids', {
            userId,
            bidAmount,
            config: {
                configId: bidGroup.uuid,
                name: bidGroup.name,
                winLimit: curWinLimit
            },
            lot:  {
                ...lot,
                date: lot.auctionDate,
                name: lot.auctionName,
                score: lot.auctionScore,
                startPrice: lot.auctionStartPrice,
                endPrice: lot.auctionEndPrice,
                status: lot.auctionStatus,
                images: lot.auctionImages,
            },
        });
    };

    delete = (bidId: string) => {
        return this.axios.delete(`/auctions/bids/${bidId}`);
    };

    setType = (bidId: string, type: string) => {
        return this.axios.patch(`/auctions/bids/${bidId}/type`, {type});
    };

    setStatus = (bidId: string, status: string) => {
        return this.axios.patch(`/auctions/bids/${bidId}/status`, {status});
    };
}
