import { AxiosInstance } from 'axios';

export default class AuthApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    register = (email: string, password: string) => {
        return this.axios.post<any>('/auth/register',
            { email: email, password: password }
        )
    };

    login = (email: string, password: string) => {
        return this.axios.post<any>('/auth/token',
            { email, password }
        )
    };

    logout = () => {
        return this.axios.get('/auth/token/logout')
    };

    refresh = () => {
        return this.axios.get<{token: string}>('/auth/token/refresh', {noSpinner: true})
    };

    sendChangePassToken = (email: string, lang: string) => {
        return this.axios.post('/users/password/reset',
            { email, lang }
        )
    };

    sendNewPass = (password: string, token: string) => {
        return this.axios.post('/users/password',
            { password, token }
        )
    };
}
