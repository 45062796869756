import { atom } from 'recoil';
import { ReactNode } from 'react';

export type ModalProps = {
    title?: string,
    size?: 'sm' | 'lg' | 'xl',
};

type ModalData = {
    component: ReactNode,
    modalProps: ModalProps,
}

const modal = atom({
    key: 'modal',
    default: {
        component: null,
        modalProps: {},
    } as ModalData,
});

export default modal;
