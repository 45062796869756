import React, { Suspense, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import useModal from 'hooks/useModal';
import AlertBox from 'components/Notifications/AlertBox';
import modal from 'context/atoms/modal';

const CustomModal = () => {
    const data = useRecoilValue(modal);
    const [ show, setShow ] = useState(false);
    const { hideModal, clearModal } = useModal();
    const { t } = useTranslation();
    const { title, ...props } = data.modalProps;
    const history = useHistory();

    useEffect(() => {
        return history.listen((loc, act) => {
            const state = loc.state as { modal: boolean } || undefined;
            if (!!state?.modal && act !== 'POP') {
                setShow(true);
            } else {
                setShow(wasShown => {
                    if (wasShown) setTimeout(clearModal, 250);
                    return false;
                });
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Modal {...props} centered show={show} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Suspense fallback={<div>{t('loading')}</div>}>
                    <AlertBox target="modal" variant="rounded" />
                    { data.component }
                </Suspense>
            </Modal.Body>
        </Modal>
    );
};

export default CustomModal;
