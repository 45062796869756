import { AxiosInstance, AxiosResponse } from 'axios';

export type IBalanceApi = {
    get: (userId: string) => Promise<AxiosResponse>,
    put: (userId: string, amount: number) => Promise<AxiosResponse<any>>,
}

export default class BalanceApi implements IBalanceApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    };

    get = (userId: string) => {
        return this.axios.get(`/balances/users/${userId}`);
    };

    put = (userId: string, amount: number) => {
        return this.axios.put(`/balances/users/${userId}`, {amount});
    };
}
