import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'react-bootstrap';
import { ToastData, Type } from 'hooks/useNotify';

type MessageProps = ToastData & {
    onClose: (id: string) => void,
};

const Message = ({title, type, ...props}: MessageProps) => {
    const [ show, setShow ] = useState(true);
    const { t } = useTranslation();

    const close = () => {
        setShow(false);
        setTimeout(() => props.onClose(props.id), 500);
    };

    // TODO add icons. SVG??
    const images = {
        [Type.Default]: 'https://via.placeholder.com/20/cce5ff/fff.png',
        [Type.Success]: 'https://via.placeholder.com/20/d4edda/fff.png',
        [Type.Info]: 'https://via.placeholder.com/20/d1ecf1/fff.png',
        [Type.Warning]: 'https://via.placeholder.com/20/fff3cd/fff.png',
        [Type.Error]: 'https://via.placeholder.com/20/f8d7da/fff.png',
    };

    const titles = {
        [Type.Default]: 'notify',
        [Type.Success]: 'success',
        [Type.Info]: 'info',
        [Type.Warning]: 'warning',
        [Type.Error]: 'error',
    };

    if (!title) {
        title = t(titles[type]);
    }

    return (
        <Toast onClose={close} show={show} delay={5000} autohide={!props.persistent}>
            <Toast.Header className={'t-' + type}>
                <img src={images[type]} className="rounded me-2" alt="" />
                <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>{props.content}</Toast.Body>
        </Toast>
    );
};

export default Message;
