import { AxiosInstance, AxiosResponse } from 'axios';
import { UserModel, UserInfoModel } from 'models/User';

type RolePatch = {
    type: string,
    rolePermissions: {
        uuid: string,
        permissions: string[],
    }[]
}

export type IUsersApi = {
    all: () => Promise<AxiosResponse<UserModel[]>>,
    get: (id: string) => Promise<AxiosResponse<UserModel>>,
    create: (data: UserInfoModel) => Promise<AxiosResponse<UserInfoModel>>,
    update: (data: UserInfoModel) => Promise<AxiosResponse<UserInfoModel>>,
    setRole: (id: string, data: RolePatch) => Promise<AxiosResponse<any>>,
    delete: (id: string) => Promise<AxiosResponse>,
}

export default class UsersApi implements IUsersApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    all = () => {
        return this.axios.get('/users');
    };

    get = (id: string) => {
        return this.axios.get<UserModel>(`/users/${id}/details`);
    };

    create = (data: UserInfoModel) => {
        return this.axios.post('/users', data);
    };

    update = (data: UserInfoModel) => {
        return this.axios.patch(`/users/${data.uuid}`, data);
    };

    setRole = (id: string, data: RolePatch) => {
        return this.axios.patch(`/users/${id}/roles`, data);
    };

    delete = (id: string) => {
        return this.axios.delete(`/users/${id}`);
    };
}
