import { AxiosInstance, AxiosResponse } from 'axios';
import { ProductModel } from 'models/Product';

export type IProductsApi = {
    get:  (companyId: string, uuid: string) => Promise<AxiosResponse<ProductModel>>,
    find: (companyId: string, status: string[], from: string, to: string) => Promise<AxiosResponse<ProductModel[]>>,
    create: (companyId: string, data: ProductModel) => Promise<AxiosResponse<ProductModel>>,
    update: (companyId: string, data: ProductModel) => Promise<AxiosResponse<ProductModel>>,
    delete: (companyId: string, productId: string) => Promise<AxiosResponse>,
}

export default class ProductsApi implements IProductsApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    };

    get = (companyId: string, uuid: string) => {
        return this.axios.get(`/companies/${companyId}/products/${uuid}`);
    };

    find = (companyId: string, status: string[], from: string, to: string) => {
        return this.axios.get(`/companies/${companyId}/products`, {
            params: {
                status,
                from,
                to,
            },
        });
    };

    create = (companyId: string, product: ProductModel) => {
        return this.axios.post(`/companies/${companyId}/products`,
            {
                ...product,
                clientId: product.client?.uuid || '',
            });
    };

    update = (companyId: string, product: ProductModel) => {
        return this.axios.patch(`/companies/${companyId}/products/${product.uuid}`,
            {
                ...product,
                clientId: product.client?.uuid || '',
            });
    };

    delete = (companyId: string, productId: string) => {
        return this.axios.delete(`/companies/${companyId}/products/${productId}`);
    }
}
