import { AxiosInstance, AxiosResponse } from 'axios';
import { InvoiceModel } from 'models/Invoices';

export type IInvoiceApi = {
    get:  (companyId: string, invoiceId: string) => Promise<AxiosResponse<InvoiceModel>>,
    find: (companyId: string, status: string[], from: string, to: string) => Promise<AxiosResponse<InvoiceModel[]>>,
    create: (companyId: string, data: InvoiceModel) => Promise<AxiosResponse<InvoiceModel>>,
    update: (companyId: string, data: InvoiceModel) => Promise<AxiosResponse<InvoiceModel>>,
    delete: (companyId: string, invoiceId: string) => Promise<AxiosResponse>,
}

export default class InvoiceApi implements IInvoiceApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    };

    get = (companyId: string, invoiceId: string) => {
        return this.axios.get(`/companies/${companyId}/invoices/${invoiceId}`);
    };

    find = (companyId: string, status: string[], from: string, to: string) => {
        return this.axios.get(`/companies/${companyId}/invoices`, {
            params: {
                status,
                from,
                to,
            },
        });
    };

    create = (companyId: string, data: InvoiceModel) => {
        return this.axios.post(`/companies/${companyId}/invoices`, data);
    };

    update = (companyId: string, data: InvoiceModel) => {
        return this.axios.patch(`/companies/${companyId}/invoices/${data.uuid}`, data);
    };

    delete = (companyId: string, invoiceId: string) => {
        return this.axios.delete(`/companies/${companyId}/invoices/${invoiceId}`);
    }
}
