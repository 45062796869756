import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import lightbox from 'context/atoms/lightbox';

const useLightbox = () => {
    const setLightbox = useSetRecoilState(lightbox);
    const history = useHistory();

    const showLightbox = (urls: string[], index?: number) => {
        setLightbox({
            urls,
            index,
        });

        history.push({
            pathname: history.location.pathname,
            search: history.location.search,
            state: { lightbox: true },
        });
    };

    const hideLightbox = () => history.goBack();

    return { showLightbox, hideLightbox };
}

export default useLightbox;
