import { DateTime } from 'luxon';
import { AxiosInstance, AxiosResponse } from 'axios';
import { BidLogModel, UserLogBaseModel } from 'models/Logs';

export type ILogsApi = {
    bids: (companyId: string, lotId: string) => Promise<AxiosResponse<BidLogModel[]>>,
    users: (companyId: string, userId: string) => Promise<AxiosResponse<UserLogBaseModel[]>>,
}

export default class LogsApi implements ILogsApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    };

    bids = (companyId: string, lotId: string) => {
        return this.axios
            .get<BidLogModel[]>(`/logs/${companyId}/bids/${lotId}`, {noSpinner: true})
            .then(res => {
                res.data.forEach((row: any) => {
                    row.executedAt = DateTime.fromISO(row.executedAt);
                })

                return res;
            });
    };

    users = (companyId: string, userId: string) => {
        return this.axios
            .get<UserLogBaseModel[]>(`/logs/${companyId}/users/${userId}`, {noSpinner: true})
            .then(res => {
                res.data.forEach((row: any) => {
                    row.executedAt = DateTime.fromISO(row.executedAt);
                })

                return res;
            });
    };
}
