import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { GuestRoute, PrivateRoute } from 'components/CustomRoutes/PrivateRoute';

import LoginPage from 'pages/Auth/LoginPage';
import RegisterPage from 'pages/Auth/RegisterPage';
import ResetPasswordPage from 'pages/Auth/ResetPasswordPage';
import CreatePasswordPage from 'pages/Auth/CreatePasswordPage';
import ChangePasswordPage from 'pages/Auth/ChangePasswordPage';

const HelpPage = React.lazy(() => import('pages/Public/HelpPage'));
const NotFoundPage = React.lazy(() => import('pages/Errors/NotFoundPage'));

const Dashboard = React.lazy(() => import('pages/Dashboard/MainPage'));
const AuctionPage = React.lazy(() => import('pages/Dashboard/AuctionPage'));
const WatchlistPage = React.lazy(() => import('pages/Dashboard/WatchlistPage'));
const CompanySettingsPage = React.lazy(() => import('pages/Dashboard/CompanySettingsPage'));
const BankAccountsPage = React.lazy(() => import('pages/Dashboard/BankAccountsPage'));
const BidsPage = React.lazy(() => import('pages/Dashboard/BidsPage'));
const LotPage = React.lazy(() => import('pages/Dashboard/LotPage'));
const UserListPage = React.lazy(() => import('pages/Dashboard/UsersPage'));
const UserEditPage = React.lazy(() => import('pages/Dashboard/UserEditPage'));
const UserCreatePage = React.lazy(() => import('pages/Dashboard/UserCreatePage'));
const PlansPage = React.lazy(() => import('pages/Dashboard/PlansPage'));
const ProfilePage = React.lazy(() => import('pages/Dashboard/ProfilePage'));
const PrivacyPage = React.lazy(() => import('pages/Public/PrivacyPage'));
const TermsPage = React.lazy(() => import('pages/Public/TermsPage'));
const ProductPage = React.lazy(() => import('pages/Dashboard/ProductPage'));
const ProductsPage = React.lazy(() => import('pages/Dashboard/ProductsPage'));
const InvoicePage = React.lazy(() => import('pages/Dashboard/InvoicePage'));
const InvoiceCreatePage = React.lazy(() => import('pages/Dashboard/InvoiceCreatePage'));
const InvoicesPage = React.lazy(() => import('pages/Dashboard/InvoicesPage'));

function Routes() {

    return (
        <Switch>
            <PrivateRoute exact path="/" component={Dashboard}/>

            {/* Requires authentication */}
            <PrivateRoute exact path="/auction" component={AuctionPage} permission="AUCTION_READ"/>
            <PrivateRoute path="/auction/lot/:id" component={LotPage} permission="AUCTION_READ"/>
            <PrivateRoute exact path="/auction/watchlist" component={WatchlistPage} permission="AUCTION_READ"/>
            <PrivateRoute exact path="/auction/bids" component={BidsPage} permission="BID_READ"/>
            <PrivateRoute exact path="/company/bank-accounts" component={BankAccountsPage} permission="BANK_READ"/>
            <PrivateRoute exact path="/company" component={CompanySettingsPage} permission="COMPANY_READ"/>
            <PrivateRoute exact path="/users" component={UserListPage} permission="USER_READ"/>
            <PrivateRoute exact path="/user/new" component={UserCreatePage} permission="USER_CREATE"/>
            <PrivateRoute path="/user/:id" component={UserEditPage} permission="USER_UPDATE"/>
            <PrivateRoute exact path="/plans" component={PlansPage} permission="PLAN_READ"/>
            <PrivateRoute exact path="/profile" component={ProfilePage}/>
            <PrivateRoute exact path="/products" component={ProductsPage} permission="PRODUCT_READ"/>
            <PrivateRoute path="/product/:id" component={ProductPage} permission="PRODUCT_READ"/>
            <PrivateRoute exact path="/invoices" component={InvoicesPage} permission="INVOICE_READ"/>
            <PrivateRoute exact path="/invoice/new" component={InvoiceCreatePage} permission="INVOICE_CREATE"/>
            <PrivateRoute path="/invoice/:id" component={InvoicePage} permission="INVOICE_READ"/>

            {/* Only for quests */}
            <GuestRoute exact path="/login" component={LoginPage}/>
            <GuestRoute exact path="/register" component={RegisterPage}/>
            <GuestRoute exact path="/reset-pass" component={ResetPasswordPage}/>
            <GuestRoute exact path="/create-pass" component={CreatePasswordPage}/>
            <GuestRoute exact path="/change-pass" component={ChangePasswordPage}/>

            {/* Any pages */}
            <Route exact path="/help" component={HelpPage}/>
            <Route exact path="/privacy" component={PrivacyPage}/>
            <Route exact path="/terms" component={TermsPage}/>

            {/* Should be last */}
            <Route component={NotFoundPage}/>
        </Switch>
    )
}

export default Routes;
