import { AxiosInstance, AxiosResponse } from 'axios';
import { PlanModel } from 'models/User';

export type IPlansApi = {
    all: (companyId: string) => Promise<AxiosResponse<PlanModel[]>>,
    get: (companyId: string, planId: string) => Promise<AxiosResponse<PlanModel>>,
    create: (companyId: string, data: PlanModel) => Promise<AxiosResponse<PlanModel>>,
    update: (companyId: string, data: PlanModel) => Promise<AxiosResponse<PlanModel>>,
    delete: (companyId: string, planId: string) => Promise<AxiosResponse>,
    assign: (userId: string, planId: string) => Promise<AxiosResponse<any>>,
}

export default class PlansApi implements IPlansApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    all = (companyId: string) => {
        return this.axios.get(`/companies/${companyId}/plans`);
    };

    get = (companyId: string, planId: string) => {
        return this.axios.get(`/companies/${companyId}/plans/${planId}`);
    };

    create = (companyId: string, data: PlanModel) => {
        return this.axios.post(`/companies/${companyId}/plans`, data);
    };

    update = (companyId: string, data: PlanModel) => {
        return this.axios.patch(`/companies/${companyId}/plans/${data.uuid}`, data);
    };

    delete = (companyId: string, planId: string) => {
        return this.axios.delete(`/companies/${companyId}/plans/${planId}`);
    };

    assign = (userId: string, planId: string) => {
        return this.axios.put(`/plans/assign`, {userId, planId});
    };
}
