import axios, { AxiosInstance } from 'axios';
import AccountApi, { IAccountApi } from './Api/Accounts';
import AuctionApi, { IAuctionApi } from './Api/Auction';
import AuthApi from './Api/Auth';
import CompaniesApi, { ICompaniesApi } from './Api/Companies';
import UsersApi, { IUsersApi } from './Api/Users';
import PlansApi, { IPlansApi } from './Api/Plans';
import ManagersApi, { IManagersApi } from './Api/Managers';
import BidsApi, { IBidsApi } from './Api/Bids';
import BalanceApi, { IBalanceApi } from './Api/Balance';
import ProductsApi, { IProductsApi } from './Api/Products';
import LogsApi, { ILogsApi } from './Api/Logs';
import InvoiceApi, { IInvoiceApi } from './Api/Invoices';

export type {
    IAccountApi,
    IAuctionApi,
    ICompaniesApi,
    IUsersApi,
    IPlansApi,
    IManagersApi,
    IBidsApi,
    IBalanceApi,
    IProductsApi,
    ILogsApi,
    IInvoiceApi,
};

declare module 'axios' {
    export interface AxiosRequestConfig {
        noSpinner?: boolean; // TODO Shouldn't be there, but I don't know the proper place
    }
}

class Api {
    private readonly axios: AxiosInstance;
    private channels: {[name: string]: ((data: any) => void)[]} = {};
    auction: IAuctionApi;
    auth: AuthApi;
    companies: ICompaniesApi;
    accounts: IAccountApi;
    users: IUsersApi;
    plans: IPlansApi;
    managers: IManagersApi;
    bids: IBidsApi;
    balance: IBalanceApi;
    products: IProductsApi;
    logs: ILogsApi;
    invoices: IInvoiceApi;

    constructor() {
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_API_HOST,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });

        this.axios.interceptors.request.use(
            config => {
                this.publish('beforeCall', config);
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        this.axios.interceptors.response.use(
            response => {
                this.publish('afterCall', response);
                return response;
            },
            error => {
                this.publish('afterCall', error);
                this.publish(`onError${error.response?.status || 'Network'}`, error);

                return Promise.reject(error);
            }
        );

        this.axios.defaults.paramsSerializer = function(params) {
            return Object.keys(params).map(key => {
                let str = encodeURIComponent(key) + '=';

                if (typeof params[key] === 'string' || typeof params[key] === 'number') {
                    return str + encodeURIComponent(params[key])
                }

                return params[key].map((val: string) => str + encodeURIComponent(val)).join('&');
            }).join('&');
        };

        this.auction = new AuctionApi(this.axios);
        this.auth = new AuthApi(this.axios);
        this.companies = new CompaniesApi(this.axios);
        this.accounts = new AccountApi(this.axios);
        this.users = new UsersApi(this.axios);
        this.plans = new PlansApi(this.axios);
        this.managers = new ManagersApi(this.axios);
        this.bids = new BidsApi(this.axios);
        this.balance = new BalanceApi(this.axios);
        this.products = new ProductsApi(this.axios);
        this.logs = new LogsApi(this.axios);
        this.invoices = new InvoiceApi(this.axios);
    }

    authorize = (token: string) => {
        // @ts-ignore
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };

    subscribe = (name: string, listener: (data: any) => void) => {
        if (!this.channels[name]) {
            this.channels[name] = []
        }

        this.channels[name].push(listener)
    };

    publish = (name: string, data: any) => {
        const channel = this.channels[name];

        if (channel?.length) {
            channel.forEach(listener => listener(data))
        }
    };
}

const api = new Api();

export default api;
