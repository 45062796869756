import { AxiosInstance, AxiosResponse } from 'axios';
import { ManagerModel } from 'models/User';

export type IManagersApi = {
    all: (companyId: string) => Promise<AxiosResponse<ManagerModel[]>>,
    getClients: (managerId: string) => Promise<AxiosResponse>,
    assign: (managerId: string, clientId: string) => Promise<AxiosResponse<any>>,
    unassign: (managerId: string, clientId: string) => Promise<AxiosResponse>,
}

export default class ManagersApi implements IManagersApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    all = (companyId: string) => {
        return this.axios.get(`/companies/${companyId}/managers`);
    }

    getClients = (managerId: string) => {
        return this.axios.get(`/managers/${managerId}/clients`);
    };

    assign = (managerId: string, clientId: string) => {
        return this.axios.post('/managers/assignclient', {managerId, clientId});
    };

    unassign = (managerId: string, clientId: string) => {
        return this.axios.delete(`/managers/${managerId}/clients/${clientId}`);
    };
}
