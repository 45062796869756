import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { Helmet, HelmetProvider  } from 'react-helmet-async';
import AppProviders from 'context/AppProviders';

import 'sass/main.scss';

import AppTheme from 'components/Themes/AppTheme';
import AutoSpinner from 'components/Spinner/Auto';
import IsMobile from 'components/IsMobile/IsMobile';
import Routes from 'components/Routes';
import CompanyLoader from 'modules/Company/CompanyLoader';
import ToastBox from 'components/Notifications/ToastBox';
import Errors from 'components/Watchers/Errors';
import LightBox from 'components/Customized/Lightbox';
import CustomModal from 'components/Customized/Modal';
import Spinner from 'components/Spinner/Spinner';
import HtmlSync from 'components/LanguageSwitcher/HtmlSync';
import AuthTryLogin from 'components/Auth/AuthTryLogin';
import Version from 'components/Watchers/Version';

const App = () => {
    return (
        <HelmetProvider>
        <Suspense fallback={<Spinner />}>
            <Helmet
                defaultTitle="Auction System"
                titleTemplate="%s - Auction System"
            >
                <meta name="description" content="Auction System CRM" />
            </Helmet>
            <AppTheme />
            <IsMobile />
            <HtmlSync />

            <RecoilRoot>
                <AppProviders>
                    <AuthTryLogin />
                    <Version />
                    <Errors />
                    <CompanyLoader />
                    <Routes />
                    <AutoSpinner />
                    <LightBox />
                    <CustomModal />
                </AppProviders>

                <ToastBox />
            </RecoilRoot>
        </Suspense>
        </HelmetProvider>
    );
};

export default App;
