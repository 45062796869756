import { ReactNode } from 'react';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import modal, { ModalProps } from 'context/atoms/modal';

const useModal = () => {
    const setModal = useSetRecoilState(modal);
    const history = useHistory();

    const showModal = (component: ReactNode, modalProps: ModalProps = {}) => {
        setModal({
            component,
            modalProps,
        });

        history.push({
            pathname: history.location.pathname,
            search: history.location.search,
            state: { modal: true },
        });
    };

    const hideModal = () => history.goBack();

    const clearModal = () => setModal(old => ({...old, component: null}));

    return { showModal, hideModal, clearModal };
}

export default useModal;
