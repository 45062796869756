import { atom } from 'recoil';
import { UserType } from 'models/User';

type AuthData = {
    sub: string,
    email: string,
    type: UserType,
    permissions: string[],
    hasApiKey: boolean,
    company: {
        name: string,
        uuid: string,
    },
    exp: number,
}

const auth = atom({
    key: 'authData',
    default: {} as AuthData,
});

export default auth;
