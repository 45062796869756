import { AxiosInstance, AxiosResponse } from 'axios';
import BankAccountModel from 'models/BankAccount';

export type IAccountApi = {
    get: () => Promise<AxiosResponse<BankAccountModel[]>>,
    create: (data: BankAccountModel) => Promise<AxiosResponse<BankAccountModel>>,
    update: (data: BankAccountModel) => Promise<AxiosResponse<BankAccountModel>>,
    delete: (id: string) => Promise<AxiosResponse>,
}

export default class AccountApi implements IAccountApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    get = () => {
        return this.axios.get('/bank/accounts');
    };

    create = (data: BankAccountModel) => {
        return this.axios.post('/bank/accounts', data);
    };

    update = (data: BankAccountModel) => {
        return this.axios.patch(`/bank/accounts/${data.uuid}`, data);
    };

    delete = (id: string) => {
        return this.axios.delete(`/bank/accounts/${id}`);
    };
}
