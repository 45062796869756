import { atom } from 'recoil';

export type LightboxData = {
    urls: string[],
    index?: number,
};

const lightbox = atom<LightboxData>({
    key: 'lightbox',
    default: {
        urls: [],
        index: 0,
    },
});

export default lightbox;
