import { AxiosInstance, AxiosResponse } from 'axios';
import LotModel from 'models/Lot';
import { decodeHtmlEntity, sortBidsByDate } from 'utils/common';

type LotsFilter = {
    filter: any,
    data: LotModel[]
}

type CarModel = {
    modelName: string,
    totalQty: number,
}

export type IAuctionApi = {
    brands: () => Promise<AxiosResponse<{ brandName: string }[]>>,
    models: (brand: string) => Promise<AxiosResponse<CarModel[]>>,
    lots: (brand: string, models: string[], qty: number) => Promise<AxiosResponse<LotsFilter>>,
    lotsByNumber: (id: string[]) => Promise<AxiosResponse<LotsFilter>>,
    marketPrice: (brandName: string, modelName: string, engineSizeCc: string, yearOfProduction: string) => Promise<AxiosResponse<LotModel[]>>,
    lot: (id: string) => Promise<AxiosResponse<LotModel>>,
    similarSold: (chassisNumber: string, year: string, mileage: string) => Promise<AxiosResponse<LotModel[]>>,
    getWatchList: (id: string) => Promise<AxiosResponse<LotModel[]>>,
    addToWatchList: (id: string, lot: LotModel) => Promise<AxiosResponse<LotModel>>,
    removeFromWatchList: (id: string, lotProviderId: string) => Promise<AxiosResponse>,
    getPhotos: (lotProviderId: string) => Promise<AxiosResponse<{auctionImages: string[]}>>,
}

export default class AuctionApi implements IAuctionApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    brands = () => {
        return this.axios.get<{ brandName: string }[]>('/auctions/brands', {noSpinner: true});
    };

    models = (brand: string) => {
        return this.axios.get(`/auctions/${brand}/models`);
    };

    lots = (brand: string, models: string[], qty=250) => {
        return this.axios
            .get<LotsFilter>(`/auctions/${brand}/lots`, {
                params: {
                    modelNames: models,
                    numberOfLots: qty,
                }
            })
            .then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    res.data.data[i].auctionStatus = res.data.data[i].auctionStatus || 'active'; // TODO wait for backend
                    res.data.data[i].clients.sort(sortBidsByDate);
                    res.data.data[i].modelGrade = decodeHtmlEntity(res.data.data[i].modelGrade);
                    res.data.data[i].chassisNumber = decodeHtmlEntity(res.data.data[i].chassisNumber);
                    res.data.data[i].transmissionType = decodeHtmlEntity(res.data.data[i].transmissionType);
                }

                for (let i = 0; i < res.data.filter.transmissionTypes.length; i++) {
                    res.data.filter.transmissionTypes[i] = decodeHtmlEntity(res.data.filter.transmissionTypes[i]);
                }

                return res;
            });
    };

    lotsByNumber = (id: string[]) => {
        return this.axios.get<LotsFilter>('/auctions/search-by-lot-numbers/lots',{
            params: {
                lotNumbers: id,
            }
        })
            .then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    res.data.data[i].auctionStatus = res.data.data[i].auctionStatus || 'active'; // TODO wait for backend
                    res.data.data[i].modelGrade = decodeHtmlEntity(res.data.data[i].modelGrade);
                    res.data.data[i].chassisNumber = decodeHtmlEntity(res.data.data[i].chassisNumber);
                    res.data.data[i].transmissionType = decodeHtmlEntity(res.data.data[i].transmissionType);
                }

                return res;
            });
    };

    similarSold = (chassisNumber: string, year: string, mileage: string) => {
        return this.axios.get<LotModel[]>('/auctions/check-previously-sold/lots',{
            params: {
                chassisNumber,
                year,
                mileage
            }
        })
            .then(this.processLots);
    };

    marketPrice = (brandName: string, modelName: string, engineSizeCc: string, yearOfProduction: string) => {
        return this.axios.get<LotModel[]>('/auctions/marketprice', {
            params: {
                brandName,
                modelName,
                engineSizeCc,
                yearOfProduction,
            }
        })
            .then(this.processLots);
    };

    lot = (id: string) => {
        return this.axios.get<LotModel>(`/auctions/lots/${id}`)
            .then(res => {
                res.data.auctionStatus = res.data.auctionStatus || 'active'; // TODO wait for backend
                res.data.modelGrade = decodeHtmlEntity(res.data.modelGrade);
                res.data.chassisNumber = decodeHtmlEntity(res.data.chassisNumber);
                res.data.transmissionType = decodeHtmlEntity(res.data.transmissionType);
                res.data.clients.sort(sortBidsByDate);
                return res;
            });
    };

    getWatchList = (id: string) => {
        return this.axios.get(`/users/lots/${id}/watchlist`)
            .then(this.processLots);
    };

    addToWatchList = (id: string, lot: LotModel) => {
        return this.axios.post(`/users/lots/${id}/watchlist`, lot, {noSpinner: true});
    };

    removeFromWatchList = (id: string, lotProviderId: string) => {
        return this.axios.delete(`/users/lots/${id}/watchlist/${lotProviderId }`, {noSpinner: true});
    };

    getPhotos = (lotProviderId: string) => {
        return this.axios.get(`/auctions/lots/${lotProviderId}/photos`);
    };

    private processLots = (res: AxiosResponse<LotModel[]>) => {
        for (let i = 0; i < res.data.length; i++) {
            res.data[i].auctionStatus = res.data[i].auctionStatus || 'active'; // TODO wait for backend
            res.data[i].modelGrade = decodeHtmlEntity(res.data[i].modelGrade);
            res.data[i].chassisNumber = decodeHtmlEntity(res.data[i].chassisNumber);
            res.data[i].transmissionType = decodeHtmlEntity(res.data[i].transmissionType);
        }

        return res;
    }
}
