import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

function RegisterPage() {
    const { t } = useTranslation();

    return (
        <div>
            <Helmet title="register" />
            form <br/>
            {t('sign_up')}
        </div>
    )
}

export default RegisterPage;
