import React, { useState } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CompanyModel from 'models/Company';

type CompanyFormProps = {
    data?: CompanyModel,
    create?: boolean,
    onFinish: (data: CompanyModel) => void,
}

const CompanyForm = ({ data, onFinish }: CompanyFormProps) => {
    const { t } = useTranslation('company');
    const [state, setState] = useState<CompanyModel>({
        name: '',
        phone: '',
        email: '',
        zipCode: '',
        city: '',
        address: '',
        companyId: '',
        companyOwner: { firstName: '', lastName: '', email: '' },
        ...data,
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(old => ({...old, [e.target.name]: e.target.value}));
    };

    return (
        <Form className="company-form">
            <Form.Group className="mb-3">
                <Form.Label>{t('company_name')}</Form.Label>
                <Form.Control type="text" name="name" defaultValue={state.name} onChange={onChange}
                              required maxLength={32} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t('company_address')}</Form.Label>
                <Form.Control type="text" name="address" defaultValue={state.address} onChange={onChange}
                              required maxLength={256} />
            </Form.Group>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('city')}</Form.Label>
                        <Form.Control type="text" name="city" defaultValue={state.city} onChange={onChange}
                                      required maxLength={32} />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('zip_code')}</Form.Label>
                        <Form.Control type="text" name="zipCode" defaultValue={state.zipCode} onChange={onChange}
                                      required maxLength={10} />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('email')}</Form.Label>
                        <Form.Control type="email" name="email" defaultValue={state.email} onChange={onChange}
                                      required />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('phone')}</Form.Label>
                        <Form.Control type="text" name="phone" defaultValue={state.phone} onChange={onChange}
                                      required maxLength={25} />
                    </Form.Group>
                </Col>
            </Row>

            <div className="text-end">
                <Button onClick={() => onFinish(state)}>{t(!data ? 'create_company' : 'update')}</Button>
            </div>
        </Form>
    );

}

export default CompanyForm;
