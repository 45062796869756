import React from 'react'
import 'utils/i18n';

import { LoadingProvider } from './LoadingSpinner';

function AppProviders({children}: any) {
    return (
        <LoadingProvider>
                {children}
        </LoadingProvider>
    )
}

export default AppProviders
