import { AxiosInstance, AxiosResponse } from 'axios';
import CompanyModel from 'models/Company';

export type ICompaniesApi = {
    my: () => Promise<AxiosResponse>,
    create: (data: CompanyModel) => Promise<AxiosResponse<CompanyModel>>,
    update: (data: CompanyModel) => Promise<AxiosResponse<CompanyModel>>,
    get: (uuid: string) => Promise<AxiosResponse>,
    setApiKey: (key: string) => Promise<AxiosResponse<any>>,
    setStamp: (companyId: string, file: File) => Promise<AxiosResponse<any>>,
    delStamp: (companyId: string, filename: string) => Promise<AxiosResponse>,
    setLogo: (companyId: string, file: File) => Promise<AxiosResponse<any>>,
    delLogo: (companyId: string, filename: string) => Promise<AxiosResponse>,
}

export default class CompaniesApi implements ICompaniesApi {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    my = () => {
        return this.axios.get('/companies');
    };

    create = (data: CompanyModel) => {
        return this.axios.post('/companies', data);
    };

    update = (data: CompanyModel) => {
        return this.axios.put('/companies', data);
    };

    get = (uuid: string) => {
        return this.axios.get(`/companies/${uuid}`);
    };

    setApiKey = (key: string) => {
        return this.axios.patch('/companies', {
            jpCenterApiKey: key
        });
    };

    setStamp = (companyId: string, file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.axios.post(`/companies/${companyId}/stamp`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    delStamp = (companyId: string, filename: string) => {
        return this.axios.delete(`/companies/${companyId}/stamp/${filename}`);
    };

    setLogo = (companyId: string, file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.axios.post(`/companies/${companyId}/logo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    delLogo = (companyId: string, filename: string) => {
        return this.axios.delete(`/companies/${companyId}/logo/${filename}`);
    };
}
