import { useRecoilState } from 'recoil';
import auth from 'context/atoms/auth';
import { parseJwt } from 'utils/common';
import Api from 'utils/api';

const useAuth = () => {
    const [my, setUser] = useRecoilState(auth);
    const isLogged = Object.keys(my).length > 0;

    const authToken = (token: string) => {
        localStorage.setItem('token', token);
        Api.authorize(token);
        setUser(parseJwt(token));
    };

    const login = (email: string, password: string) => {
        return Api.auth.login(email, password)
            .then(res => authToken(res.data.token));
    };

    const register = (email: string, password: string) => {
        return Api.auth.register(email, password)
            .then(res => authToken(res.data.token));
    };

    const logout = () => {
        Api.auth.logout()
            .then(() => {
                localStorage.removeItem('token');
                window.location.reload();
            })
    };

    return { my, isLogged, login, logout, register, authToken };
};

export default useAuth;
