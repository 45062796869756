import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Modal } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import useLightbox from 'hooks/useLightbox';
import lightbox from 'context/atoms/lightbox';

const Lightbox = () => {
    const data = useRecoilValue(lightbox);
    const [ show, setShow ] = useState(false);
    const { hideLightbox } = useLightbox();
    const history = useHistory();

    useEffect(() => {
        return history.listen((loc, act) => {
            const state = loc.state as { lightbox: boolean } || undefined;
            setShow(!!state?.lightbox && act !== 'POP');
        });
        // eslint-disable-next-line
    }, []);

    const images = data.urls.map(u => ({
        original: u,
        thumbnail: u + '&h=50',
    }));

    return (
        <Modal size="xl" centered show={show} onHide={hideLightbox}>
            <Modal.Body>
                <ImageGallery items={images} startIndex={data.index} thumbnailPosition={isMobile ? 'bottom' : 'left'} showPlayButton={false} />
            </Modal.Body>
        </Modal>
    );
};

export default Lightbox;
