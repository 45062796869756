import React from 'react';
import { RoleModel } from 'models/User';
import { ClientModel } from 'models/Bid';

export function parseJwt(token: string | null) {
    if (token === null) return null;

    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const obj = JSON.parse(jsonPayload);
    obj.permissions = obj.permissions.split(',');

    return obj;
}

export function sleep(ms: number) {
    const init = new Date();
    let cur = new Date();

    do {
        cur = new Date()
    }
    while (cur.getTime() - init.getTime() < ms);
}

export function japanTime() {
    const d = new Date();
    d.setTime(d.getTime() + (d.getTimezoneOffset() + 9 * 60) * 60 * 1000);

    return d;
}

export function timeUntil(date: Date | string | number): [number, string] {
    const diff = new Date(date).getTime() - japanTime().getTime();
    const days = Math.floor(diff / 86400000);
    const hours = Math.floor((diff % 86400000) / 3600000);
    const minutes = Math.round(((diff % 86400000) % 3600000) / 60000);

    if (hours < 0 || minutes < 0) {
        return [0, 'ended']
    }

    return days ? [days, 'days'] : (hours ? [hours, 'hours'] : [minutes, 'minutes']);
}

export function decodeHtmlEntity(str: string) {
    return str.replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
    });
}

export function addDays(date: Date | string | number, days: number) {
    const d = new Date(date);
    d.setDate(d.getDate() + days);

    return d;
}

export function createPreset(selected: {[module: string]: string[]}, all: {[module: string]: string[]}) {
    const converted: RoleModel[] = [];

    for (let module in all) {
        if (!all.hasOwnProperty(module)) continue;
        converted.push({
            uuid: '',
            role: module,
            permissions: module in selected ? [...selected[module]] : [],
        })
    }

    return converted;
}

export function updatePreset(selected: {[module: string]: string[]}, old: RoleModel[], myPermissions: string[]) {
    let updated: RoleModel[] = [];

    const filter = (user: string[], module: string, my: string[]) => {
        const perms: string[] = [];

        for (let perm of user) {
            if (my.includes(`${module}_${perm}`)) {
                perms.push(perm);
            }
        }

        return perms;
    }

    for (let roleModel of old) {
        updated.push({
            ...roleModel,
            permissions: roleModel.role in selected ? filter(selected[roleModel.role], roleModel.role, myPermissions) : [],
        });
    }

    return updated;
}

export function range(start: number, end?: number): number[] {
    if (end) {
        return [...Array(end - 1).keys()].map(i => i + start);
    }

    return [...Array(start).keys()];
}

export function objectHasValue(item: {[key:string]: any}, filter: string, keys?: string | string[]) {
    if (!keys) {
        for (const prop of Object.values(item)) {
            if (
                (typeof prop === "string" && prop.toLowerCase().includes(filter.toLowerCase())) ||
                (typeof prop === "number" && prop.toString().includes(filter))
            ) {
                return true;
            }
        }
    } else {
        if (typeof keys === "string") {
            keys = [keys];
        }

        for (const key of keys) {
            if (!(key in item)) continue;

            const prop = item[key];

            if (
                (typeof prop === "string" && prop.toLowerCase().includes(filter.toLowerCase())) ||
                (typeof prop === "number" && prop.toString().includes(filter))
            ) {
                return true;
            }
        }
    }

    return false; // Only one false here, if nothing found!
}

export class CustomSet<Type> {
    private readonly is: (a: any, b: any) => boolean;
    private list: Type[] = [];

    constructor(comparator: (a: any, b: any) => boolean) {
        this.is = comparator;
    }

    add(value: any) {
        for (let item of this.list) {
            if (this.is(item, value)) {
                return false;
            }
        }

        this.list.push(value);

        return true;
    }

    remove(value: any) {
        this.list.forEach((item, i) => {
            if (this.is(item, value)) {
                delete this.list[i];

                return true;
            }
        })

        return false;
    }

    [Symbol.iterator]() {
        return this.list.values();
    }
}

declare global {
    interface Array<T> {
        remove(o: T): Array<T>;
    }
}

Array.prototype.remove = function(a) {
    let ax;

    while ((ax = this.indexOf(a)) !== -1) {
        this.splice(ax, 1);
    }

    return this;
};

export const getByPath = function(o: object, s: string) {
    // @ts-ignore
    return s.split('.').reduce((p, c) => p ? p[c] : undefined, o)
}

export const sortBidsByDate = (ca: ClientModel, cb: ClientModel) => {
    let a = new Date(ca.bid.updatedAt).valueOf();
    let b = new Date(cb.bid.updatedAt).valueOf();
    // @ts-ignore
    return isFinite(a) && isFinite(b) ? (a < b) - (a > b) : NaN
}

export function truncate(str: string, n: number){
    return (str.length > n) ? str.substr(0, n-1) + '\u2026' : str;
}

export function replaceItemAtIndex<T>(arr: T[], index: number, newValue: T) {
    if (index === -1) return arr;
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export function removeItemAtIndex<T>(arr: T[], index: number) {
    if (index === -1) return arr;
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export const nToBr = (text: string) => {
    return text.split('\n').map((item, i) => {
        return (i === 0) ? item : [<br key={i} />, item];
    });
}
